import React from "react"

const JoAmbRibo = () => (
    <svg version="1.1" x="0px" y="0px" width="300px" height="450px" viewBox="0 100 1200 1500">
    <g>
      <path fill="currentColor" d="M103.23,259.3v103.23c0,10.45,4.3,15.98,13.52,15.98h1.23c8.6,0,12.9-5.53,12.9-15.98V6.14h104.46V365.6
        c0,63.9-33.18,103.23-102,103.23h-30.11c-66.36,0-94.63-35.02-94.63-96.47V259.3H103.23z"/>
      <path fill="currentColor" d="M503.24,345.94c0,79.26-43.63,124.12-113.06,124.12h-19.66c-74.35,0-114.29-44.86-114.29-124.12v-216.9
        C256.22,50.39,289.4,0,369.9,0h19.66c81.11,0,113.68,51,113.68,129.04V345.94z M398.16,118.59c0-17.2-6.14-25.81-18.43-25.81
        c-14.13,0-18.43,8.6-18.43,25.81V352.7c0,14.13,4.92,22.74,19.05,22.74c12.9,0,17.82-8.6,17.82-22.74V118.59z"/>
      <path fill="currentColor" d="M141.33,980.84h-46.7l-6.76,66.98H0l58.37-457.16h124.74l63.9,457.16H148.7L141.33,980.84z M103.84,890.52
        h28.27l-14.13-138.87L103.84,890.52z"/>
      <path fill="currentColor" d="M256.22,590.66h121.05l25.19,213.83l26.42-213.83h114.29v457.16h-87.25V798.35l-38.71,249.47h-36.25
        l-38.71-249.47v249.47h-86.02V590.66z"/>
      <path fill="currentColor" d="M565.91,590.66h153.62c52.23,0,80.49,23.96,80.49,86.02v50.39c0,43.63-27.65,64.52-42.4,69.43
        c21.51,6.76,51,23.96,51,78.04v67.59c0,70.66-27.65,105.69-82.95,105.69H565.91V590.66z M667.3,668.7v98.31h14.13
        c11.67,0,18.43-5.53,18.43-22.73V690.2c0-14.13-3.69-21.51-17.82-21.51H667.3z M667.3,846.28v120.43h18.43
        c12.29,0,17.2-4.92,17.2-24.58v-72.51c0-16.59-4.3-23.35-19.05-23.35H667.3z"/>
      <path fill="currentColor" d="M113.67,1632.34H12.29v-457.16h159.15c44.86,0,75.58,26.42,75.58,80.49v60.22c0,42.4-22.12,61.45-39.94,69.43
        c17.2,6.76,39.94,25.81,39.94,59.6v151.16c0,17.2,3.07,26.42,6.14,33.18v3.07H153c-4.3-5.53-7.99-15.98-7.99-32.57v-132.72
        c0-15.36-3.07-22.12-17.2-22.12h-14.13V1632.34z M113.67,1358.9h12.9c12.9,0,18.43-5.53,18.43-22.12v-61.45
        c0-16.59-2.46-21.51-16.59-21.51h-14.75V1358.9z"/>
      <path fill="currentColor" d="M369.9,1632.34H268.52v-457.16H369.9V1632.34z"/>
      <path fill="currentColor" d="M392.02,1175.18h153.62c52.23,0,80.49,23.96,80.49,86.02v50.39c0,43.63-27.65,64.52-42.4,69.43
        c21.51,6.76,51,23.96,51,78.04v67.59c0,70.66-27.65,105.69-82.95,105.69H392.02V1175.18z M493.4,1253.22v98.31h14.13
        c11.67,0,18.43-5.53,18.43-22.73v-54.07c0-14.13-3.69-21.51-17.82-21.51H493.4z M493.4,1430.79v120.43h18.43
        c12.29,0,17.2-4.92,17.2-24.58v-72.51c0-16.59-4.3-23.35-19.05-23.35H493.4z"/>
      <path fill="currentColor" d="M898.94,1514.97c0,79.27-43.63,124.12-113.06,124.12h-19.66c-74.35,0-114.29-44.86-114.29-124.12v-216.9
        c0-78.65,33.18-129.04,113.68-129.04h19.66c81.11,0,113.67,51,113.67,129.04V1514.97z M764.37,1085.47h79.88l-30.72,75.58h-77.42
        L764.37,1085.47z M793.87,1287.62c0-17.2-6.15-25.81-18.43-25.81c-14.13,0-18.43,8.6-18.43,25.81v234.11
        c0,14.13,4.92,22.73,19.05,22.73c12.9,0,17.82-8.6,17.82-22.73V1287.62z"/>
    </g>
</svg>
)

export default JoAmbRibo
